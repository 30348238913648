export const warningGridItemFrag = `
    id
    organizationId
    identifier
    type
    data
    ignored
    link
    createdAt
    user {
        name
        surname
        email
        titleBeforeName
        titleAfterName
    }
`;
