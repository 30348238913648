import { GqlService } from "../modules/content/service/gql.service";
import { GridInput } from "@it2go/data-grid";
import { MassEmailsGridObject } from "@libs/types/src/chcrm/object-type/mass-emails/mass-email.object";
import { filterFrag } from "./types/filter.types";
import { massEmailGridItemFrag } from "./types/mass-email.types";
import { Injectable } from "@angular/core";
import { MassEmailPreviewObject } from "@libs/types/src/chcrm/object-type/mass-emails/mass-email-preview.object";
import { MassEmailPreviewInput } from "@libs/types/src/chcrm/input-type/mass-emails/mass-email-preview.input";


@Injectable()
export class MassEmailService extends GqlService {

    getMassEmails(input: GridInput | null) {
        return this.query<MassEmailsGridObject>(
            `
                query($input: GridInput) {
                    massEmail {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${massEmailGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getMassEmailPreview(input: MassEmailPreviewInput) {
        return this.query<MassEmailPreviewObject>(
            `
                query($input: MassEmailPreviewInput!) {
                    massEmail {
                        preview(input: $input) {
                            mailId
                            preview
                            example
                        }
                    }
                }
            `,
            { input },
        );
    }
}