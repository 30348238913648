import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { OkObject, WarningGridObject } from '@it2go/types';
import { warningGridItemFrag } from './types/warning.types';
import { WarningDeleteInput } from '@libs/types/src/chcrm/input-type/warning/warning-delete.input';
import { GqlService } from '../modules/content/service/gql.service';
import { filterFrag } from './types/filter.types';
import { WarningSilenceInput } from '@libs/types/src/chcrm/input-type/warning/warning-silence.input';
import { WarningDonorCustomInput } from '@libs/types/src/chcrm/input-type/warning/warning-donor-custom.input';

@Injectable()
export class WarningService extends GqlService {

    public getWarnings(input: GridInput | null) {
        return this.query<WarningGridObject>(
            `
                query($input: GridInput) {
                    warning {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${warningGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createCustomDonorWarning(input: WarningDonorCustomInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WarningDonorCustomInput!) {
                    donor {
                        createWarning(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public silenceWarning(input: WarningSilenceInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WarningSilenceInput!) {
                    warning {
                        silence(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public deleteWarning(input: WarningDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: WarningDeleteInput!) {
                    warning {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
