import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from './types/filter.types';
import { GqlService } from '../modules/content/service/gql.service';
import {
    ExpectedDonationGridItemObject,
    ExpectedDonationGridObject,
} from '@libs/types/src/chcrm/object-type/donation/expected-donation.object';
import { expectedDonationGridItemFrag } from './types/expected-donation.types';
import {
    ExpectedDonationCreateInput,
    ExpectedDonationDeleteInput,
} from '@libs/types/src/chcrm/input-type/donation/expected-donation.input';
import { OkObject } from '@it2go/types';

@Injectable()
export class ExpectedDonationService extends GqlService {

    getExpectedDonations(input: GridInput | null) {
        return this.query<ExpectedDonationGridObject>(
            `
                query($input: GridInput) {
                    expectedDonation {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${expectedDonationGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createExpectedDonation(input: ExpectedDonationCreateInput) {
        return this.mutate<ExpectedDonationGridItemObject>(
            `
                mutation($input: ExpectedDonationCreateInput!) {
                    expectedDonation {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteExpectedDonation(input: ExpectedDonationDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ExpectedDonationDeleteInput!) {
                    expectedDonation {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
