export const expectedDonationGridItemFrag = `
    id
    from
    to
    comment
    collection {
        id
        name
    }
    amount
    donorId
`;
