export const massEmailGridItemFrag = `
    id
    date
    name
    kind
    recipients {
        id
        name
    }
    filters
`;